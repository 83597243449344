import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const BannerDiaExpress = ({ banner, bannerMobile, colStyle }) => {
  return (
    <section>
      <div className='container mt-5 d-none d-lg-block'>
        <div className='row'>
          <div className={`col ${colStyle}`}>
            <a href='https://bit.ly/3myVgps' target="_blank" rel="noreferrer">
              <GatsbyImage image={banner.childImageSharp.gatsbyImageData} alt="Banner Compre no ZAP" />
            </a>
          </div>
        </div>
      </div>
      <div className='d-block d-lg-none mt-5'>
        <a href='https://bit.ly/3myVgps' target="_blank" rel="noreferrer">
          <GatsbyImage
            image={bannerMobile.childImageSharp.gatsbyImageData}
            alt="Banner Compre no ZAP" />
        </a>
      </div>
    </section>
  )
}

export default BannerDiaExpress
