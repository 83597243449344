import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player/youtube'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Comments, FacebookProvider } from 'react-facebook'

/* COMPONENTS */
import SectionLancamentos from '../components/SectionLancamentos/sectionLancamentos'
import InputSearch from '../components/receitas/SideBarReceitas/inputSearch'
import Layout from '../components/Layout/index'
import SideBar from '../components/receitas/SideBarReceitas/sideBarReceitas'
import Menu from '../components/receitas/receitas-header'
import BannerDiaExpress from '../components/Diaexpress/banner-dia-express'

/* STYLES */
import '../pages/styles/receitas.scss'
import './receitas-template.scss'

/* ICONS */
import iconeTempo from '../images/receitas/tempo.svg'
import iconeServe from '../images/receitas/serve.svg'
import iconeDificuldade from '../images/receitas/dificuldade.svg'
import whatsapp from '../images/receitas/whatsapp.svg'
import facebook from '../images/receitas/facebook.svg'
// import instagram from '../images/receitas/instagram.svg'
import pinterest from '../images/receitas/pinterest.svg'

export default function Receita ({ data }) {
  const [receitaSEO, setReceitaSEO] = useState()
  useEffect(() => {
    const recipeData = renderRichText(data.receita.ingredientes)
    console.log('recipeData', recipeData)
    let resultRecipeList
    recipeData.map(content => {
      if (content.type !== 'p' && content.type !== 'hr') {
        resultRecipeList = content.props.children.map(childrens => {
          return childrens.props.children[0].props.children[0]
        })
      }
    })

    console.log('data.receitas', JSON.parse(data.receita.modoDePreparo.raw))
    const preparo = JSON.parse(data.receita.modoDePreparo.raw).content[0].content.map(value => {
      return {
        '@type': 'HowToStep',
        text: value.content[0].content[0].value
      }
    })
    // eslint-disable-next-line camelcase
    const receita_SEO = {
      '@context': 'http://schema.org',
      '@type': 'Recipe',
      name: `${data.receita.title}`,
      image: `${data.receita.image.localFile.childImageSharp.gatsbyImageData.src}`,
      description: `${data.receita.descricao !== null ? data.receita.descricao.descricao : null}`,
      totalTime: `${data.receita.duracao}`,
      recipeYield: `${data.receita.serve}`,
      keywords: [`${data.receita.tagsReceitas !== null ? data.receita.tagsReceitas.map(tag => { return tag }) : null}`],
      recipeCategory: `${data.receita.categoria}`,
      recipeIngredient: resultRecipeList,
      recipeInstructions: preparo,
      author: {
        '@type': 'Person',
        name: 'DIA Supermercado'
      },
      video: {
        '@type': 'VideoObject',
        name: `${data.receita.title}`,
        description: `${data.receita.descricao !== null ? data.receita.descricao.descricao : null}`,
        thumbnailUrl: [
          `${data.receita.image.localFile.childImageSharp.gatsbyImageData.src}`
        ],
        uploadDate: `${new Date(data.receita.updatedAt).toISOString()}`,
        contentUrl: `${data.receita.urLyoutube}`,
        embedUrl: `${data.receita.urLyoutube}`
      }
    }
    setReceitaSEO(receita_SEO)
  }, [])

  const pageUrl = data.site.siteMetadata.siteUrl + '/receitas/' + data.receita.categoriaSlug + '/' + data.receita.slug + '/'
  const imageSrc = data.site.siteMetadata.siteUrl + data.receita.image.localFile.childImageSharp.gatsbyImageData.src
  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/receitas/', name: 'Receitas' },
      { path: `/receitas/${data.receita.categoriaSlug}/`, name: `${data.receita.categoria}` },
      { path: `/receitas/${data.receita.categoriaSlug}/${data.receita.slug}/`, name: `${data.receita.title}` }
    ]
  }
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <title>Receita de {data.receita.title} | DIA Supermercados</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description"
          content={`No DIA Supermercados, além dessa receita de ${data.receita.title}, você também encontra receitas de ${data.TodasReceitas.edges[0].node.categoria}, ${data.TodasReceitas.edges[1].node.categoria}, dentre outras. Confira!`} />
        <meta name="author" content="DIA Supermercados" />
        <meta property="og:description"
          content={`No DIA Supermercados, além dessa receita de ${data.receita.title}, você também encontra receitas de ${data.TodasReceitas.edges[0].node.categoria}, ${data.TodasReceitas.edges[1].node.categoria}, dentre outras. Confira!`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={data.receita.title} />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:image" content={imageSrc} />
        <meta property="og:site_name" content={data.site.siteMetadata.siteName} />
        <meta property="og:url" content={pageUrl} />
        <meta property="article:author" content="DIA Supermercados" />
        <meta property="article:section" content={data.receita.categoria} />
        <meta property="fb:app_id" content={data.site.siteMetadata.facebookAppId} />
        <script type="application/ld+json">{JSON.stringify(receitaSEO, null, 4)}</script>
      </Helmet>
      <section id="receitas">
        <section id="filtragem-home">
          <div className="menu-receitas">
            <Menu handleActive={data.receita.categoriaSlug} />
          </div>
        </section>
        <section className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <div className='d-block d-sm-none' >
                <InputSearch />
              </div>
              <h2 className='text-uppercase text-red font-size-20px'>{data.receita.title}</h2>
              <section id="content_video">
                <div className="row">
                  <div className="col-md-12">
                    <div className="receita-video-interno">
                      <ReactPlayer className="react-youtube-player"
                        controls
                        playing
                        url={data.receita.urLyoutube}
                        width='100%'
                        height='100%'
                      />
                    </div>
                  </div>
                </div>
              </section>
              <div className="d-flex justify-content-between mt-4">
                <div><h3 className="text-uppercase text-dark mb-0">{data.receita.subtitulo}</h3></div>
                <div className="d-flex">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl + '?utm_source=facebook&utm_medium=social&utm_campaign=clouddog_receitas')}&display=popup`}
                    target="_blank"
                    style={{ width: '50px', display: 'inline' }}
                    rel="noopener noreferrer">
                    <img src={facebook} alt="Botão de compartilhamento Facebook" />
                  </a>
                  {/* <a
                    href='#'
                    style={{ width: '50px', display: 'inline' }}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img src={instagram} alt="Botão de compartilhamento Instagram" />
                  </a> */}
                  <a style={{ width: '50px', display: 'inline' }}
                    data-action="share/whatsapp/share"
                    href={`https://www.pinterest.com/pin/create/link/?url=${pageUrl}&description=Vi esta receita no site do DIA e gostaria de compartilhar`}
                    target="_blank" rel="noopener noreferrer">
                    <img src={pinterest} alt="Botão de compartilhamento Pinterest" />
                  </a>
                  <a style={{ width: '50px', display: 'inline' }}
                    data-action="share/whatsapp/share"
                    href={`https://api.whatsapp.com/send?text=Vi esta receita no site do DIA e gostaria de compartilhar com você: ${encodeURIComponent(pageUrl + '?utm_source=whatsapp&utm_medium=social&utm_campaign=clouddog_receitas')}`}
                    target="_blank" rel="noopener noreferrer">
                    <img src={whatsapp} alt="Botão de compartilhamento Whatsapp" />
                  </a>
                </div>
              </div>
              {data.receita.descricao && <p className="mt-2 mb-5">{data.receita.descricao.descricao}</p>}
              {data.receita.duracao || data.receita.serve || data.receita.dificuldade
                ? <>
                  <hr className="hrBlue" />
                  <div className="justify-content-around d-flex">
                    {data.receita.duracao && <p className="text-lg-left text-center d-lt-flex align-items-center font-Lato text-uppercase"><img src={iconeTempo} alt="Icone de Tempo" className="mr-2" /><br className="d-lg-none d-block" /><b>Tempo:</b><br className="d-lg-none d-block" />&nbsp;{data.receita.duracao}</p>}
                    {data.receita.serve && <p className="text-lg-left text-center d-lg-flex align-items-center font-Lato text-uppercase"><img src={iconeServe} alt="Icone de Serve" className="mr-2" /><br className="d-lg-none d-block" /><b>Serve:</b><br className="d-lg-none d-block" />&nbsp;{data.receita.serve} {data.receita.serve > 1 ? 'pessoas' : 'pessoa' }</p>}
                    {data.receita.dificuldade && <p className="text-lg-left text-center d-lg-flex align-items-center font-Lato text-uppercase"><img src={iconeDificuldade} alt="Icone de Dificuldade" className="mr-2" /><br className="d-lg-none d-block" /><b>Dificuldade:</b><br className="d-lg-none d-block" />&nbsp;{data.receita.dificuldade}</p>}
                  </div> </> : ''
              }

              <hr className="hrBlue" />
              <div id="detalhes-receitas" className="d-lg-flex mt-4">
                <div className="width-custom pr-lg-4 border-right-custom mb-lg-0 mb-2">
                  <div className="ingredientes">
                    <h2>ingredientes</h2>
                    <div className="ingredientes-content">
                      {renderRichText(data.receita.ingredientes)}
                    </div>
                  </div>
                </div>
                <hr className="hrBlue d-lg-none d-block" />
                <div className="width-custom px-lg-4 mt-lg-0 mt-2">
                  <div className="modo-preparo">
                    <h2>Modo de Preparo</h2>
                    <div className="modo-preparo-content">
                      {renderRichText(data.receita.modoDePreparo)}
                    </div>
                  </div>
                </div>
              </div>
              {data.receita.tagsReceitas &&
                <>
                  <hr className="hrBlue" />
                  {data.receita.tagsReceitas.map((tag, index) => (
                    <div className="mr-3 tagsReceitas font-Lat d-inline-block" key={index}>#{tag}</div>
                  ))}
                </>
              }
              <hr className="hrBlue" />
              <section id="receita-comentarios">
                <div className="row">
                  <div className="col-md-12">
                    <div className="comentarios-title">
                      <h3 className="text-red text-uppercase">Gostou dessa receita?</h3>
                      <h4>Conte pra nós!</h4>
                    </div>
                    <div className="comentarios-text-area" style={{ marginBottom: 3 + 'rem', width: 100 + '%' }}>
                      <FacebookProvider appId="551710272030851" language="pt_BR">
                        <Comments href={pageUrl} width="100%" />
                      </FacebookProvider>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className='col-12 col-lg-4'>
              <SideBar />
            </div>
          </div>
        </section>
        <section className='container-fluid bg-dia-light-gray'>
          <section className="container py-5 p-0">
            <SectionLancamentos />
          </section>
        </section>
        <div className='mb-5'>
          <BannerDiaExpress banner={data.bannerDiaExpress} bannerMobile={data.bannerDiaExpressMobile} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`query ($slug: String!) {
  receita: contentfulReceitas(slug: {eq: $slug}) {
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    ingredientes {
      raw
    }
    modoDePreparo {
      raw
    }
    descricao {
      descricao
    }
    subtitulo
    serve
    urLyoutube
    categoria
    tagsReceitas
    title
    dificuldade
    duracao
    categoriaSlug
    slug
    updatedAt
  }
  TodasReceitas: allContentfulReceitas(
    limit: 10
    sort: {fields: [createdAt], order: DESC}
  ) {
    edges {
      node {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        id
        urLyoutube
        slug
        categoria
        categoriaSlug
        title
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
      facebookAppId
      author
      title
      siteName
      tagManagerId
    }
  }
  bannerDiaExpress: file(
    relativePath: {eq: "receitas/banner-diaexpress-desktop.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerDiaExpressMobile: file(
    relativePath: {eq: "receitas/banner-diaexpress-mobile.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 428, height: 267, layout: CONSTRAINED)
    }
  }
}
`
